.message-sender {
    width: 80%;
    margin: 0 auto;
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.form-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-label {
    flex: 1;
    text-align: left;
    margin-right: 10px;
}

.dropdown-button {
    flex: 3;
    width: 100%;
}

textarea {
    width: 100%;
}
