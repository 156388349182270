.ProfilePage-page {
    padding: 20px;
}

.search-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.ProfilePage-card {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
}

.profile-details {
    display: flex;
    justify-content: space-between;
}

.details-section {
    flex-grow: 1;
    margin-right: 20px;
}

.photo-section .photo-placeholder {
    width: 160px;
    height: 210px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #666;
}

.table-container {
    overflow-x: auto;
}

.restrictions-table {
    margin-top: 20px;
}
